<template>
    <ef-dialog title="设置收银折扣" :visible.sync="show" top="60px" @confirm="handleStaffCash">
        <el-form ref="staffCash" :model="form">
            <el-form-item label="收银整单折扣最大值" prop="discount" :rules="rules.discount">
                <el-input v-model="form.discount" />
            </el-form-item>

            <el-form-item label="收银商品单价折扣最大值" prop="priceDiscount" :rules="rules.discount">
                <el-input v-model="form.priceDiscount" />
            </el-form-item>
            <el-form-item label="临时变更会员折扣最大值" prop="memberDiscountLimit" :rules="rules.discount">
                <el-input v-model="form.memberDiscountLimit" />
            </el-form-item>
        </el-form>
    </ef-dialog>
</template>

<script>
import EfDialog from 'components/EfDialog';
import { post } from '../../../../request/http';

export default {
    name: 'StaffCashEdit',
    components: { EfDialog },
    data() {
        return {
            show: false,
            form: {
                staffCode: '',
                discount: '',
                priceDiscount: '',
                memberDiscountLimit: '10',
            },
            rules: {
                discount: {
                    pattern: /^(0(\.[1-9])|[1-9](\.[0-9])?|10|10(\.0))$/,
                    message: '请正确输入折扣值',
                },
            },
        };
    },
    methods: {
        open(staffCashData) {
            this.show = true;
            this.form.priceDiscount = staffCashData.priceDiscount || '';
            this.form.memberDiscountLimit = staffCashData.memberDiscountLimit || '';
            this.form.discount = staffCashData.discount || '';
            this.form.staffCode = staffCashData.staffCode || '';
        },
        handleStaffCash: function () {
            const _this = this;
            this.$refs.staffCash.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                if (!_this.form.discount) {
                    _this.$message.error('请填写收银整单最大折扣，若不想设置请填写10');
                    return;
                }
                if (!_this.form.priceDiscount) {
                    _this.$message.error('请填写商品单价最大折扣，若不想设置请填写10');
                    return;
                }
                if (!_this.form.priceDiscount) {
                    _this.$message.error('请填写临时变更会员折扣最大值，若不想设置请填写10');
                    return;
                }
                const _param = {
                    staffCode: _this.form.staffCode,
                    discount: _this.form.discount,
                    priceDiscount: _this.form.priceDiscount,
                    memberDiscountLimit: _this.form.memberDiscountLimit,
                };
                await post(`/system/staffCash/discount/saveUpdate`, _param);
                _this.show = false;
                this.$emit('completed');
            });
        },
    },
};
</script>
